import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ProductList } from "../../../../enum/ProductList";
import { ProductsTypeListe } from "../../../../enum/ProductList";


const ProductLists = ({ productTypeId }) => {
  const navigate = useNavigate();
  const [activeClassId, setActiveClassId] = useState("all");
  const subTypeList = ProductsTypeListe.filter(
    (item) => item.id == productTypeId
  ).pop().subTypeList;

  const handleClick = (id) => {
    setActiveClassId(id);
  };
  const goTo = (id) => {
    navigate("/produits/" + id);
  };

  useEffect(() => {
    setActiveClassId("all");
  }, [productTypeId]);

  return (
    <div className="mt-20">
      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-12 filters-group-wrap">
            <div className="filters-group">
              <ul className="container-filter list-inline mb-0 filter-options text-center">
                <li
                  className={
                    activeClassId === "all"
                      ? "list-inline-item categories-name border text-dark rounded active"
                      : "list-inline-item categories-name border text-dark rounded"
                  }
                  onClick={() => handleClick("all")}
                >
                  Tous
                </li>
                {subTypeList.map((item) => (
                  <li
                    onClick={() => handleClick(item.id)}
                    className={
                      activeClassId === item.id
                        ? "list-inline-item categories-name border text-dark rounded active"
                        : "list-inline-item categories-name border text-dark rounded"
                    }
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
      {/*end container*/}

      <Container>
        <Row className="justify-content-md-center">
          {activeClassId === "all" &&
            ProductList.filter((item) => item.productType == productTypeId).map(
              (element) => {
                return (
                  <Col sm={6} md={4}>
                    <div
                      className="card shop-list border-0 shadow position-relative overflow-hidden cursor-pointer mt-10"
                      onClick={() => goTo(element.id)}
                    >
                      <div className="shop-image position-relative overflow-hidden shadow d-flex justify-content-center">
                        <img
                          src={require("../../../../img/" +
                            element.slug +
                            ".png")}
                          className="img-fluid h-300px"
                          alt=""
                        />
                      </div>
                      <div className="card-body content p-4">
                        <a
                          onClick={() => goTo(element.id)}
                          className="text-dark product-name h6 d-flex justify-content-center"
                        >
                          {element.name}
                        </a>
                      </div>
                    </div>
                  </Col>
                );
              }
            )}
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          {ProductList.filter(
            (item) =>
              item.productSubType == activeClassId &&
              item.productType == productTypeId
          ).map((element) => {
            return (
              <Col sm={6} md={4}>
                <div
                  className="card shop-list border-0 shadow position-relative overflow-hidden cursor-pointer mt-10"
                  onClick={() => goTo(element.id)}
                >
                  <div className="shop-image position-relative overflow-hidden shadow d-flex justify-content-center">
                    <img
                      src={element.img}
                      className="img-fluid h-300px"
                      alt=""
                    />
                  </div>
                  <div className="card-body content p-4">
                    <a
                      onClick={() => goTo(element.id)}
                      className="text-dark product-name h6 d-flex justify-content-center"
                    >
                      {element.name}
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default ProductLists;
