import React, { useEffect } from "react";
import Container from "./components/Container";

import Headerswiper from "./components/Headerswiper";
import ColorProduct from "../../generalComponents/ColorProduct";
import { Helmet } from "react-helmet-async";

var Scroll = require("react-scroll");

const Index = () => {
  useEffect(() => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>CJA | Produits</title>
        <meta
          name="description"
          content="Découvrez notre gamme exclusive de produits, où vous pouvez personnaliser chaque détail, de la couleur à la qualité. Chez CJA, nous sommes fiers de vous proposer les produits de TPR, synonymes d'excellence dans l'industrie. Explorez notre sélection et choisissez des produits qui répondent à vos besoins, tout en bénéficiant de l'assurance de qualité et de style que seul TPR peut offrir."
        />
        <link rel="canonical" href="/produits" />
      </Helmet>

      <Headerswiper />
      <div className="position-breadcrumb">
        <nav aria-label="breadcrumb" className="d-inline-block">
          <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
            <li className="breadcrumb-item">
              <a href="/">Accueil</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Produits
            </li>
          </ul>
        </nav>
      </div>

      <ColorProduct button={false} />
      <Container />
    </>
  );
};

export default Index;
