import { color } from "@cloudinary/url-gen/qualifiers/background";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ColorProduct = ({ button }) => {
  const [selectedColor, setSelectedColor] = useState("#FFFF00");
  const [productBackgroundColor, setProductBackgroundColor] =
    useState("transparent");
  const [selectedName, setSelectedName] = useState("");
  const [selectedColorIndex, setSelectedColorIndex] = useState(0); // Initialize with the index of the first color

  useEffect(() => {
    // Set the initial color and name based on the first color
    const firstColor = colors[0];
    setSelectedColor(firstColor.color);
    setProductBackgroundColor(firstColor.color);
    setSelectedName(firstColor.name);
  }, []);

  const handleBlockClick = (backgroundColor, selectedName, index) => {
    setProductBackgroundColor(backgroundColor);
    handleNameColor(selectedName);
    setSelectedColorIndex(index); // Set the index of the selected color
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
    setProductBackgroundColor(event.target.value);
  };
  const colors = [
    {
      name: "RAL 1003",
      color: "rgb(249, 168, 0)",
    },
    {
      name: "RAL 1002",
      color: "rgb(210, 170, 109)",
    },
    {
      name: "RAL 1001",
      color: "rgb(208, 176, 132)",
    },
    {
      name: "RAL 1014",
      color: "rgb(221, 196, 154)",
    },
    {
      name: "RAL 1016",
      color: "rgb(241, 221, 56)",
    },
    {
      name: "RAL 1019",
      color: "rgb(164, 143, 122)",
    },
    {
      name: "RAL 1024",
      color: "rgb(186, 143, 76)",
    },
    {
      name: "RAL 2004",
      color: "rgb(226, 83, 3)",
    },
    {
      name: "RAL 3002",
      color: "rgb(155, 35, 33)",
    },
    {
      name: "RAL 3005",
      color: "rgb(89, 25, 31)",
    },
    {
      name: "RAL 3014",
      color: "rgb(203, 115, 117)",
    },
    {
      name: "RAL 3018",
      color: "rgb(199, 63, 74)",
    },
    {
      name: "RAL 4001",
      color: "rgb(129, 97, 131)",
    },
    {
      name: "RAL 4004",
      color: "rgb(101, 30, 56)",
    },
    {
      name: "RAL 5001",
      color: "rgb(15, 76, 100)",
    },
    {
      name: "RAL 5010",
      color: "rgb(0, 79, 124)",
    },
    {
      name: "RAL 6005",
      color: "rgb(17, 66, 50)",
    },
    {
      name: "RAL 7001",
      color: "rgb(140, 150, 157)",
    },
    {
      name: "RAL 7005",
      color: "rgb(108, 110, 107)",
    },
    {
      name: "RAL 7016",
      color: "rgb(56, 62, 66)",
    },
    {
      name: "RAL 7021",
      color: "rgb(47, 50, 52)",
    },
    {
      name: "RAL 8003",
      color: "rgb(126, 75, 38)",
    },
    {
      name: "RAL 8007",
      color: "rgb(112, 69, 42)",
    },
    {
      name: "RAL 8017",
      color: "rgb(68, 47, 41)",
    },
    {
      name: "RAL 9001",
      color: "rgb(233, 224, 210)",
    },
    {
      name: "RAL 9002",
      color: "rgb(215, 213, 203)",
    },
    {
      name: "RAL 9005",
      color: "rgb(14, 14, 16)",
    },
    {
      name: "RAL 9007",
      color: "rgb(135, 133, 129)",
    },
  ];
  const handleNameColor = (selectedName) => {
    setSelectedName(selectedName);
  };

  const navigate = useNavigate();
  return (
    <section className="section ">
      <div className="container pd">
        <div className="row align-items-center">
          <div className="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0 pd-m">
            <div className="section-title ms-md-4">
              <h4 className="title">Choisir Votre Meilleur Couleur</h4>

              <ul className="list-unstyled text-warning h5 mb-0">
                <li className="list-inline-item">
                  <i className="mdi mdi-star" />
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star" />
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star" />
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star" />
                </li>
                <li className="list-inline-item">
                  <i className="mdi mdi-star" />
                </li>
              </ul>
              {/* <h5 className="mt-4 py-2">Overview :</h5> */}
              <p className="text-muted">
                Explorez notre superbe gamme de couleurs élégantes et donnez vie
                à votre produit selon vos préférences.
              </p>
              <div className="row mt-4 pt-2">
                {colors.map((item,index) => (
                  <div
                  key={index}
                  className={`cursor-pointer ${
                    selectedColorIndex === index ? "selected" : ""
                  }`}
                    onClick={() => handleBlockClick(item.color, item.name, index)}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "2px",
                      margin: "10px 10px",
                      backgroundColor: item.color,
                      border: selectedColorIndex === index ? "2px solid blue" : "none",
                    }}
                  ></div>
                ))}
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <h2 style={{ marginRight: "15px" }}>Réf:</h2>{" "}
                  <h3>{selectedName}</h3>
                </div>

                {/*end col*/}
              </div>
              {/*end row*/}
              {button ? (
                <div className="mt-4 pt-2">
                  <a
                    onClick={() => {
                      navigate("/produits");
                    }}
                    className="btn btn-outline-primary mt-2"
                  >
                    Voir Nos Produits
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-5 pd-m">
            <div className="tns-outer" id="tns1-ow">
              <div id="tns1-mw" className="tns-ovh">
                <div className="tns-inner" id="tns1-iw">
                  <div
                    className="image_wrap"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="product"
                      src={require("../../img/serie_tw60_confort(1).png")}
                      alt=""
                      style={{
                        position: "absolute",
                        width: "350px",

                        backgroundColor: productBackgroundColor,
                      }}
                    />
                    <img
                      className="product1"
                      src={require("../../img/serie_tw60_confort(2)(2).png")}
                      alt=""
                      style={{
                        position: "absolute",
                        width: "350px",
                        opacity: "0.3",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end col*/}

          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </section>
  );
};

export default ColorProduct;
