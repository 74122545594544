import React from "react";

const Header = () => {
  return (
    <>
    
      <section
        className="bg-half-260 d-table w-100 "
        style={{
          backgroundImage: `url(${require("../../../../img/cjajerba2.jpg")}`,
          
        }}
      >
        <div className="bg-overlay" />
        <div className="container bg-overlaykb">
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="pages-heading title-heading ">
                <h2 className="text-white" >
                  {" "}
                  A Propos{" "}
                </h2>

                <p className=" para-desc mb-0 mx-auto" style={{color : "#cecece"}}>
                Découvrez l'excellence de CJA, votre partenaire de confiance depuis 2019, spécialisé dans la distribution d'aluminium de haute qualité pour des solutions durables et innovantes.
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
   

        </div>{" "}
        {/*end container*/}
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>

      
    </>
  );
};

export default Header;
