// import serie40 from "../img/serie_40_elipse.png";
// import serie75 from "../img/serie_75_blindee.png";
// import serie45 from "../img/serie_ex45_prestige.png";
// import serietw60 from "../img/serie_tw60_confort.png";
// import serie22 from "../img/serie_22_ellipse.png";
// import serie67 from "../img/serie_67_ellipse.png";
// import serie160 from "../img/serie_160_confort.png";
// import serieex60 from "../img/serie_ex60_prestige_ligne_droite.png";
// import serieex60el from "../img/serie_ex60_prestige_ligne_elliptique.png";
// import seriets60 from "../img/serie_ts60_confort.png";
// import serie52horrptvep from "../img/serie52_horizon_rpt_vep.png";
// import serie52horvec from "../img/serie52_horizon_vec.png";
// import serie52horth from "../img/serie52_horizon_th.png";
// import serie84evo from "../img/serie84_evoulution_vec.png";
// import cloisonsjointcreux from "../img/cloisons_lisses_joint_creux.png";
// import cloisonsstructure from "../img/cloisons_lisses_structure_visible.png";
// import serievolets from "../img/serie-volets-coulissants.png";
// import seriegrille from "../img/serie-grille-de-ventilation.png";
// import serie15ellipse from "../img/serie15_ellipse.png";
// import seriebrise from "../img/serie-brise-soleil.png";
// import fenetrejalousie from "../img/fenetre_jalousie_tpr.png";
// import pergolebioclimatique from "../img/pergola_bioclimatique_tpr19.png";
// import gardecorps from "../img/garde_corps_encastre_gs.png";
// import moteur1 from "../img/kit_de_remplacement.png";
// import moteur2 from "../img/kitdemotorisation.png";
// import moteur3 from "../img/rs100_solar_io.png";
// import p1 from "../img/alba_key_cremone.png";
// import p2 from "../img/micro_key_cremone.png";
// import p3 from "../img/luna_key_cremone130_external_fixing.png";
// import p4 from "../img/handlinga_and_flat_catch_handle.png";
// import p5 from "../img/connector9mm.png";
import tpr from "../img/logo-tpr.png";
import alustar from "../img/alustar.png";
import Akfix from "../img/akafix.png";

// 1 : Battante / 2 : Rideaux / 3 : Coulissante / 4 : Autres
export const ProductList = [
  {
    id: 1,
    productSubType: 2,
    productType: 1,
    slug: "serie52_horizon_rpt_vep",
    name: "SÉRIE 52 HORIZON+ RPT VEP",
    title: "Système de Façade Rideau à Rupture de Pont Thermique VEP",
    describtion1:
      "• Structure constituée de meneaux verticaux et de traverses horizontales de meme section et de largeur intérieure visible de 52 mm.",
    describtion2:
      "• Panneaux de remplissage “visions ou opaques” en profilé multi-chambre à rupture de pont thermique assemblés par une double équerre à sertir en aluminium et étanchés par une colle spéciale.",
    describtion3:
      "• Assemblage des traverses sur meneaux assuré par des blocs spéciaux en aluminium et des bouchons de finition élastique absorbant les effets de dilatation des traverses.",
    describtion4:
      "• Panneaux fixes reposant sur des cales “supports panneaux” et tenus à l’ossature par des blocs à pions de qualité sur les côtés latéraux du cadre.",
    describtion5:
      "• Double vitrage en panneaux vision de 24 à 28 mm d’épaisseur et d’un poids maxi de 130kg.",
    describtion6:
      "• Visserie et boulonnerie entièrement en acier inoxydable anti-corrosion.",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 2,
    productSubType: 2,
    productType: 1,
    slug: "serie52_horizon_vec",
    name: "SÉRIE 52 HORIZON+ VEC",
    describtion1: "Système de Façade Rideau à Rupture de Pont Thermique.",
    describtion2: "Façade à Vitrage Extérieur Collé (VEC)",
    describtion3:
      "• Structure constituée de meneaux verticaux et de traverses horizontales de même section et de largeur intérieure visible de 52mm. ",
    describtion4:
      "• Panneaux de remplissage “visions ou opaques” en profilé multi chambre à rupture de pont thermique assemblés par une double équerre à sertir en aluminium et étanchés par une colle spéciale.",
    describtion5:
      "• Assemblage des traverses sur meneaux assuré par des blocs spéciaux en aluminium et des bouchons de finition élastique absorbant les effets de dilatation des traverses.",
    describtion6:
      "• Panneaux fixes reposant sur des cales “supports panneaux” et tenus à l’ossature par des blocs à pions de qualité sur les côtés latéraux du cadre.",
    describtion7:
      "• Double vitrage en panneaux vision de 24 à 36mm d’épaisseur et d’un poids maxi de 130kg.",
    describtion8:
      "• Visserie et boulonnerie entièrement en acier inoxydable anti-corrosion.",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 3,
    productSubType: 2,
    productType: 1,
    slug: "serie52_horizon_th",
    name: "SÉRIE 52 HORIZON+ TH",
    describtion1: "Système de Façade Rideau à Rupture de Pont Thermique",
    describtion2: "Facade à Trame Horizontale (TH)",
    describtion3:
      "•  Structure constituée de meneaux verticaux et de traverses horizontales de même section et de largeur intérieure visible de 52mm.",
    describtion4:
      "•  Isolation thermique assurée par un joint sur les meneaux et par des écarteurs en polyamide placés aux nez des traverses.",
    describtion5:
      "•  Remplissages “vision ou opaques” tenus à l’ossature par un profil “serreur vissé” directement au nez des traverses.",
    describtion6:
      "•  Aspect extérieur de la façade finalisé par des joints verticaux et des capots horizontaux de différentes formes et de largeur visible de 52mm.",
    describtion7:
      "•  Panneaux ouvrants de type VEC constitués par des profils dormants et des ouvrants à rupture de pont thermique et assemblés par une double équerre à sertir en Aluminium.",
    describtion8:
      "•  Joints extérieurs et intérieurs en EPDM qui assurent l’étanchéité à l’air et à l’eau.",
    describtion9:
      "•  Drainage en cascade sur les deux niveaux qui assure une meilleure étanchéité à l’air et à l’eau.",
    describtion10: "",
  },

  {
    id: 4,
    productSubType: 1,
    productType: 1,
    slug: "serie_40_elipse",
    name: "SÉRIE 40 ELLIPSE",
    describtion1: "• Dormant d’épaisseur 40 mm.",
    describtion2: "• Ouvrant d’épaisseur 47 mm.",
    describtion3: "• Double vitrage:",
    describtion4: "• 20mm parclose elliptique.",
    describtion5: "• 28mm parclose droite.",
    describtion6: "• Accessoires haut de gamme.",
    describtion7: "• Parclose elliptique à coupe d’onglet sans pièce d’angle.",
    describtion8: "• Joints d’étanchéité en EPDM d’excellente qualité.",
    describtion9: "• Étanchéité A.E renforcée.",
    describtion10: "• A*3 E*7B V*C3",
  },
  {
    id: 5,
    productSubType: 1,
    productType: 1,
    slug: "serie_75_blindee",
    name: "SÉRIE 75 BLINDÉE",
    describtion1: "• Dormant épaisseur 75 mm.",
    describtion2: "• Ouvrant épaisseur 75 mm.",
    describtion3: "• Epaisseur Alu 2 mm minimum.",
    describtion4: "• Lames de blindage Alu ou Acier.",
    describtion5: "• Crémone et serrures spéciales à fermeture multipoints.",
    describtion6: "• Rotation sur paumelles en applique.",
    describtion7: "• Joint en EPDM de haute qaulité",
    describtion8: "• Assemblage dormant à 45°",
    describtion9: "• Assemblage ouvrant à 45°.",
    describtion10: "• Vitrage jusqu’à 42mm.",
  },
  {
    id: 6,
    productSubType: 1,
    productType: 1,
    slug: "serie_ex45_prestige",
    name: "SÉRIE EX45 PRESTIGE",
    describtion1: "• Dormant d’épaisseur 45 mm.",
    describtion2: "• Ouvrant d’épaisseur 53 mm.",
    describtion3: "• Double vitrage:",
    describtion4: "• 24 mm parclose elliptique.",
    describtion5: "• 32 mm parclose droite.",
    describtion6: "• Accessoires haut de gamme.",
    describtion7: "• Parclose elliptique à coupe d’onglet sans pièce d’angle.",
    describtion8: "• Joints d’étanchéité en EPDM d’excellente qualité.",
    describtion9: "• Étanchéité A.E renforcée.",
    describtion10: "• A*3 E*7B V*C3",
  },
  {
    id: 7,
    productSubType: 1,
    productType: 1,
    slug: "serie_tw60_confort",
    name: "SÉRIE TW60 CONFORT",
    describtion1: "• Dormant d’épaisseur 60 mm.",
    describtion2: "• Ouvrant d’épaisseur 68 mm.",
    describtion3: "• Double vitrage:",
    describtion4: "• 34 mm parclose elliptique.",
    describtion5: "• 42 mm parclose droite.",
    describtion6: "• Accessoires haut de gamme.",
    describtion7: "• Parclose elliptique à coupe d’onglet sans pièce d’angle.",
    describtion8: "• Joints d’étanchéité en EPDM d’excellente qualité.",
    describtion9: "• Étanchéité A.E renforcée.",
    describtion10: "• A*3 E*7B V*C3",
  },
  {
    id: 8,
    productSubType: 3,
    productType: 1,
    slug: "serie_22_ellipse",
    name: "SÉRIE 22 ELLIPSE",
    title: "Fenêtres et Porte-Fenêtres Coulissantes",
    describtion1: "• Dormant d’épaisseur 40 mm.",
    describtion2: "• Ouvrant d’épaisseur 26 mm.",
    describtion3: "• Simple vitrage: 6, 8 mm.",
    describtion4: "• Double vitrage: 18 mm.",
    describtion5: "• Fermetures encastrées.",
    describtion6: "• Galets simples ou doubles réglables de 90kg.",
    describtion7: "• Joints d’étanchéité en EPDM d’excellente qualité.",
    describtion8: "• Double joints brosse fin-seal.",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 9,
    productSubType: 3,
    productType: 1,
    slug: "serie_67_ellipse",
    name: "SÉRIE 67 ELLIPSE",
    describtion1: "• Dormant d’épaisseur 40 mm.",
    describtion2: "• Ouvrant d’épaisseur 29 mm.",
    describtion3: "• Simple vitrage: 6, 8 et 10 mm.",
    describtion4: "• Double vitrage: 18 et 20 mm.",
    describtion5: "• Accessoires haut de gamme.",
    describtion6: "• Fermetures multipoints pré réglées avec gâches réglables.",
    describtion7: "• Galets simples ou doubles réglables de 90 à 180kg.",
    describtion8: "• Joints d’étanchéité en EPDM d’excellente qualité.",
    describtion9: "• Doubles joints brosse fin-seal.",
    describtion10: "• Étanchéité A.E renforcée.",
  },
  {
    id: 10,
    productSubType: 3,
    productType: 1,
    slug: "serie_160_confort",
    name: "SÉRIE 160 CONFORT",
    describtion1: "• Dormant d’épaisseur 60 mm.",
    describtion2: "• Ouvrant d’épaisseur 34 mm.",
    describtion3: "• Simple vitrage: 6, 8 et 10 mm.",
    describtion4: "• Double vitrage: jusqu’à 26 mm.",
    describtion5: "• Accessoires haut de gamme.",
    describtion6: "• Fermetures multipoints pré réglées avec gâches réglables.",
    describtion7: "• Galets simples ou doubles réglables de 90 à 180kg.",
    describtion8: "• Doubles joints brosse fin-seal.",
    describtion9: "• Étanchéité A.E renforcée.",
    describtion10: "• A*3   E*7B   V*C3",
  },
  {
    id: 29,
    productSubType: 3,
    productType: 1,
    slug: "serie_ex60_prestige_ligne_droite",
    name: "SÉRIE EX60 PRESTIGE LIGNE DROITE",
    describtion1: "• Dormant  d’épaisseur 60 mm.",
    describtion2: "• Ouvrant d’épaisseur 34 mm.",
    describtion3: "• Simple vitrage: 6, 8 et 10 mm.",
    describtion4: "• Double vitrage: 20 et 26 mm.",
    describtion5: "• Accessoires haut de gamme.",
    describtion6: "• Fermetures multipoints pré réglées avec gâches réglables.",
    describtion7: "• Galets simples ou doubles réglables de 90 à 180kg.",
    describtion8: "• Double joints brosse fin seal.",
    describtion9: "• Etanchéité A.E renforcée.",
    describtion10: "• A*3   E*7B   V*C3",
  },
  {
    id: 30,
    productSubType: 3,
    productType: 1,
    slug: "serie_ex60_prestige_ligne_elliptique",
    name: "SÉRIE EX60 PRESTIGE LIGNE ELLIPTIQUE",
    describtion1: "• Dormant d’épaisseur 60 mm.",
    describtion2: "• Ouvrant d’épaisseur 34 mm.",
    describtion3: "• Simple vitrage: 6, 8 et 10 mm.",
    describtion4: "• Double vitrage: jusqu’à 26 mm.",
    describtion5: "• Accessoires haut de gamme.",
    describtion6: "• Fermetures multipoints pré réglées avec gâches réglables.",
    describtion7: "• Galets simples ou doubles réglables de 90 à 180kg.",
    describtion8: "• Double joints brosse fin seal.",
    describtion9: "• Etanchéité A.E renforcée.",
    describtion10: "• A*3   E*7B   V*C3",
  },
  {
    id: 31,
    productSubType: 3,
    productType: 1,
    slug: "serie_ts60_confort",
    name: "SÉRIE TS60 CONFORT",
    describtion1:
      "• Dormants à 2 rails à coupe d’onglet d’épaisseur 160 x 56 mm avec un chemin de roulement en inox.",
    describtion2: "• Ouvrants à coupe d’onglet d’épaisseur 70 x 103 mm.",
    describtion3:
      "• Parcloses droites pour double ou triple vitrage jusqu’à 52 mm.",
    describtion4:
      "• Fermeture multipoints de 2 à 5 points actionnées par une poignet spécifique simple ou double.",
    describtion5: "• Roulettes à levage de grande charge jusqu’à 400kg.",
    describtion6: "• Joints de vitrage en EPDM d’excellente qualité.",
    describtion7: "• Etanchéité par double joints en EPDM ou brosse fin seal.",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 11,
    productSubType: 2,
    productType: 1,
    slug: "serie84_evoulution_vec",
    name: "SÉRIE 84 EVOLUTION VEC",
    describtion1: "Système de Façade Rideau à Rupture de Pont Thermique.",
    describtion2:
      "• Structure constituée de meneaux verticaux et de traverses horizontales de même section et de largeur intérieure visible de 84 mm.",
    describtion3:
      "• Panneaux de remplissage “vision ou opaques” en profilé multi chambre à rupture de pont thermique assemblés par une double équerre à sertir en aluminium.",
    describtion4:
      "• Panneaux fixes tenus à l’ossature par des blocs à pions sur les côtés latéraux du cadre. Ce système assure une pose très rapide et un alignement systématique des cadres.",
    describtion5:
      "• Panneaux ouvrants articulés sur des compas inox d’un poids max de 130kg et verrouillés par une crémone à plusieurs points de fermeture.",
    describtion6:
      "• Etanchéité du système assurée par une série de 3 joints de battement, des croix et des angles préformés pour éviter les coupes aux angles.",
    describtion7:
      "• Drainage en cascade sur 2 niveaux assurant une meilleure étanchéité à l’air et à l’eau.",
    describtion8:
      "• Double vitrage en panneaux vision de 24 à 36 mm d’épaisseur et d’un poids max de 130kg.",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 12,
    productSubType: 4,
    productType: 1,
    slug: "cloisons_lisses_joint_creux",
    name: "SÉRIE 16 ELLIPSE CREUX",
    describtion1: "Cloisons lisses à joint creux",
    describtion2: "• Epaisseur : 82mm",
    describtion3: "• Profilé de structure assemblé à 90°",
    describtion4: "• Vitrage de 5mm en double parois",
    describtion5: "• Vide entre vitrage de 58mm",
    describtion6: "• Panneau plein en double parois de 16mm",
    describtion7: "• Isolant entre panneau de 50mm",
    describtion8: "• Joint creux entre panneaux en bois de 5mm",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 13,
    productSubType: 4,
    productType: 1,
    slug: "cloisons_lisses_structure_visible",
    name: "SÉRIE 16 ELLIPSE VISIBLE",
    describtion1: "Cloisons lisses structure visible",
    describtion2: "• Épaisseur de 75 mm.",
    describtion3: "• Largeur aluminium de 40 mm.",
    describtion4: "• Profilé de structure assemblé à 90°.",
    describtion5: "• Vitrage de 4 à 8 mm en double parois.",
    describtion6: "• Vide entre vitrage de 58 mm.",
    describtion7: "• Panneau plein en double paroi de 10 à 13 mm.",
    describtion8: "• Isolant entre panneau de 50 mm.",
    describtion9: "• Cache parclose clippage.",
    describtion10: "",
  },
  {
    id: 14,
    productSubType: 4,
    productType: 1,
    slug: "serie-volets-coulissants",
    name: "SÉRIE VOLETS COULISSANTS",
    describtion1:
      "• Cadre en profilés aluminium à coupe d’onglet d’épaisseur 60 mm.",
    describtion2:
      "• Remplissage plein ou ajouré composé de lames en profilés aluminium, glissé dans les rainures du cadre ou vissé.",
    describtion3:
      "• Rail de coulissement suspendu avec un cache frontal et guidage bas pour un poids max de 100kg.",
    describtion4:
      "• Volet persienne avec un remplissage en lames aluminium de 10 x 50 mm inclinées à 30° et un pas de 40 mm.",
    describtion5:
      "• Volet plein avec un remplissage en lames aluminium tubulaires de 8.5 x 120 mm ou 24 x 97 mm.",
    describtion6: "• Volet barreaudé avec des profilés de barreaudage vissé.",
    describtion7: "• Protection solaire.",
    describtion8: "• Protection contre le vent et la pluie.",
    describtion9: "• Ventilation et aération.",
    describtion10: "• Occultation visuelle partielle ou totale.",
  },
  {
    id: 15,
    productSubType: 4,
    productType: 1,
    slug: "serie-grille-de-ventilation",
    name: "SÉRIE GRILLE DE VENTILATION",
    describtion1:
      "PROFILE PORTEUR: 2 types de porteurs sont disponibles en fonction de la structure existante",
    describtion2:
      "• Profil porteur lourd et tubulaire (ep 40 x 58 mm) pour pose en tableau, hauteur 2000 mm, entre axe 1500 mm max",
    describtion3:
      "• Profil porteur léger et plat ( ep 13 x 88 mm) pour pose directe sur façade ou sur ossature, hauteur  2000 mm, entre axe 1500 mm max.",
    describtion4: "SUPPORT DE LAMES:",
    describtion5:
      "• Les supports de lames sont clippsés sur des tringles en aluminium qui fixent le pas des lames puis vissées sur le profil porteur.",
    describtion6:
      "• Les lames sont ensuite clippsés sur leurs supports par un système qui facilite le montage et l’installation des lames tout en offrant une grande résistance à l’arrachage.",
    describtion7: "LAMES:",
    describtion8:
      "• Grande lame filante en forme de Z (ep 65 x 78 mm) avec un pas de 70 mm.",
    describtion9:
      "• Petite lame complémentaire (ep 29 x 37 mm) permettant d’assurer la continuité visuelle de la grille sur les parties opaques ou sur les portes.",
    describtion10:
      "• Différents façonnages sont possible tels que la coupe d’onglet pour une intégration épousant la forme des bâtiments.",
  },
  {
    id: 16,
    productSubType: 4,
    productType: 1,
    slug: "serie15_ellipse",
    name: "SÉRIE 15 ELLIPSE",
    describtion1:
      "Gardes Corps à panneaux à vitrés,à tôle, ou à barreaux avec ou sans barres horizontales sous main courante",
    describtion2: "• Main courante ronde ou elliptique",
    describtion3:
      "• Panneauté en verre feuilleté (4.4.2) ou tôle décor aluminium.",
    describtion4: "• Lisses horizontales en tube  D32 mm",
    describtion5:
      "• Fixation sur les potelets par pièce en aluminium invisibles de l’extérieure.",
    describtion6: "• Potelet (raidisseur) tubulaire 40x20mm",
    describtion7:
      "• Platine de fixation sur dalle avec réglage tridimensionnel",
    describtion8: "• Embouts pour lisses et mains courantes",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 17,
    productSubType: 4,
    productType: 1,
    slug: "serie-brise-soleil",
    name: "SÉRIE BRISE SOLEIL",
    describtion1:
      "• Lames en profilés aluminium extrudés de forme rectangulaire ou en aile d’avion en plusieurs dimensions.",
    describtion2: "• Lames rectangulaires de 140 à 300 mm de largeur.",
    describtion3: "• Lames en aile d’avion de 210 à 400 mm de largeur.",
    describtion4:
      "• Supports Flasques d’extrémités en tôle d’aluminium pour des lames fixes selon les différentes orientations proposées (0, 15, 30 ou 45°).",
    describtion5:
      "• Support Fourche intermédiaire en aluminium pour des lames filantes permettant de choisir parmi les différentes orientations proposées (0, 15, 30 ou 45°).",
    describtion6:
      "• Supports Flasques d’extrémités en tôle d’aluminium pour des lames mobiles motorisées.",
    describtion7:
      "• Supports des lames fixés directement sur la construction ou sur des Profils porteurs en aluminium.",
    describtion8:
      "• Brise Soleil à lames mobiles montées entre poteaux et maintenues par des flasques solidaires d’un vérin électrique.",
    describtion9:
      "• Brises Soleil TPR BS installés horizontalement ou verticalement, en façade, en casquette ou en tableau.",
    describtion10:
      "• Régulation solaire et lumineuse, Protection contre le vent et la pluie, Ventilation et aération.",
  },
  {
    id: 18,
    productSubType: 4,
    productType: 1,
    slug: "fenetre_jalousie_tpr",
    name: "FENÊTRE JALOUSIE TPR",
    describtion1:
      "La fenêtre jalousie détient un système d’ouverture et de fermeture à lames horizontales orientables, permettant à la jalousie d’offrir ventilation, éclairage, protection solaire et protection contre l’intrusion.",
    describtion2: "CARACTÉRISTIQUES TECHNIQUES:",
    describtion3: "• Dormant épaisseur 60mm.",
    describtion4: "• Lames en verre (5 à 6mm).",
    describtion5: "• Joints en EPDM de haute qualité.",
    describtion6: "• Couvre-joint rapporté de différentes formes.",
    describtion7: "• Bavette rapportée après pose.",
    describtion8: "• Trois positions d’inclinaison des lames.",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 19,
    productSubType: 4,
    productType: 1,
    slug: "pergola_bioclimatique_tpr19",
    name: "PERGOLA BIOCLIMATIQUE TPR 19",
    describtion1:
      "Avec sa structure en aluminium extrudé spécialement étudiée pour les espaces extérieurs, ses lames orientables et sa motorisation pour une ouverture pas à pas, elle permet de profiter de sa véranda, quelle que soit la météo.",
    describtion2: "CARACTÉRISTIQUES TECHNIQUES:",
    describtion3:
      "• Lames orientables en aluminium extrudées de 190X34mm ep 2mm, équipées de joints en EPDM, permettant une parfaite étanchéité.",
    describtion4:
      "• Traverses latérales de 125X230mm ep 3mm (coupe droite 90°).",
    describtion5:
      "• Système de drainage d’eau intégré dans le poteau de la pergola.",
    describtion6:
      "• Poteaux carrés de 130X130 mm (coupe droite 90° et facile à l’assemblage).",
    describtion7: "• Accessoires inoxydables et bouchons en Inox.",
    describtion8:
      "• Motorisation radio (ouverture pas à pas) avec télécommande équipée de détecteurs de vent.",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 20,
    productSubType: 4,
    productType: 1,
    slug: "garde_corps_encastre_gs",
    name: "GARDE CORPS ENCASTRÉ GS 85",
    describtion1:
      "Nouveau garde-corps alliant parfaitement esthétique et sécurité. Entièrement composé de panneaux en verre dont la partie basse est encastrée dans un socle carré en aluminium extrudé.",
    describtion2: "CARACTÉRISTIQUES TECHNIQUES:",
    describtion3: "• Socle carré en aluminium extrudé 85x125mm.",
    describtion4:
      "• Aspect extérieur défini par des panneaux vitrés de dimension maximale 1,2m x 1,1m.",
    describtion5: "• Vitrage feuilleté (88.4) / (1010.4).",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 21,
    productSubType: 1,
    productType: 2,
    slug: "alustarproduct",
    name: "Actuellement, les images ne sont pas encore disponibles !!",
    describtion1: "!! Actuellement, les images ne sont pas encore disponibles, cependant, nous sommes en train de les préparer pour une prochaine mise à jour !!.",
    describtion2: "Pour obtenir de plus amples informations, nous vous invitons à nous contacter directement.",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  // {
  //   id: 22,
  //   productSubType: 2,
  //   productType: 2,
  //   slug: "kitdemotorisation",
  //   name: "KIT DE Motorisation",
  //   describtion1: "",
  //   describtion2: "",
  //   describtion3: "",
  //   describtion4: "",
  //   describtion5: "",
  //   describtion6: "",
  //   describtion7: "",
  //   describtion8: "",
  //   describtion9: "",
  //   describtion10: "",
  // },
  // {
  //   id: 23,
  //   productSubType: 3,
  //   productType: 2,
  //   slug: "rs100_solar_io",
  //   name: "RS 100 SOLAR IO",
  //   describtion1: "",
  //   describtion2: "",
  //   describtion3: "",
  //   describtion4: "",
  //   describtion5: "",
  //   describtion6: "",
  //   describtion7: "",
  //   describtion8: "",
  //   describtion9: "",
  //   describtion10: "",
  // },
  {
    id: 24,
    productSubType: 1,
    productType: 3,
    slug: "fire_rated",
    name: "Fire Rated PU Foam",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 25,
    productSubType: 2,
    productType: 3,
    slug: "pu_formas",
    name: "PU FOAMS FILLING FIXING INSULATING",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 26,
    productSubType: 3,
    productType: 3,
    slug: "e60",
    name: "E60 CONTACT CLEANER",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 27,
    productSubType: 4,
    productType: 3,
    slug: "waterproof_epoxy",
    name: "E300 Waterproof Epoxy",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 28,
    productSubType: 2,
    productType: 3,
    slug: "universal_fast_adhesive",
    name: "705 Universal Fast Adhesive",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 32,
    productSubType: 2,
    productType: 3,
    slug: "hot-melt-straight",
    name: "HM220 EVA Hot Melt Straight Edge Banding Adhesive",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },
  {
    id: 33,
    productSubType: 2,
    productType: 3,
    slug: "pu_express_montage_adhesive",
    name: "610 PU Express Montage Adhesive",
    describtion1: "",
    describtion2: "",
    describtion3: "",
    describtion4: "",
    describtion5: "",
    describtion6: "",
    describtion7: "",
    describtion8: "",
    describtion9: "",
    describtion10: "",
  },

];

export const ProductsTypeListe = [
  {
    id: 1,
    name: "TPR",
    logo: tpr,
    h: 50,
    w: 150,
    subTypeList: [
      { id: 1, name: "Battante" },
      { id: 2, name: "Facades Rideaux" },
      { id: 3, name: "Coulissante" },
      { id: 4, name: "Autres Produits" },
    ],
  },
  {
    id: 2,
    name: "Alustar",
    logo: alustar,
    h: 50,
    w: 120,
    subTypeList: [
     
    ],
  },
  {
    id: 3,
    name: "Akfix",
    logo: Akfix,
    h: 50,
    w: 120,
    subTypeList: [
      
    ],
  },
];
