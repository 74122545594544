import React from "react";

import Cathalogue from "../certifCatha/components/Cathalogue";
import { Helmet } from "react-helmet-async";

const Admin = () => {
  return (
    <>
    <Helmet>
        <title>CJA | Cathalogues</title>
        <meta
          name="description"
          content="Explorez notre gamme de catalogues de produits CJA pour découvrir une variété d'options élégantes de comptoirs en aluminium. Trouvez l'inspiration et choisissez parmi nos designs et finitions variés pour sublimer votre espace."
        />
        <link rel="canonical" href="/catalogues" />
      </Helmet>

      <Cathalogue />
    </>
  );
};

export default Admin;
