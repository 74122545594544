import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "swiper/css/navigation";

import "./sponsorSwiper.css";

// import required modules
import { Autoplay } from "swiper/modules";

const SponsorSwiper = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <>
      
      <Swiper
      style={{width:"100%", height:"100%",marginTop:"20px",marginBottom:"10px"}}
        slidesPerView={isMobile ? 2 : 5}
        spaceBetween={isMobile ? 80 : 150}
        loop={true}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        speed={3000}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            height: "100%",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/tpr.png")} />
        </SwiperSlide>

        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/alustar.jpg")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/akafix.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            width: "80px",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/asa.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",

            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/came.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/lavaal.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/master.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/tpr.png")} />
        </SwiperSlide>

        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/alustar.jpg")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/panda.jpeg")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/somfy.png")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",

            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/spi.jpg")} />
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <img src={require("../../../../img/yilmaz.png")} />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SponsorSwiper;
