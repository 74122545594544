import React, { useEffect } from "react";
import WhoWeAre from "./componets/WhoWeAre";

import Header from "./componets/Header";
import { Helmet } from "react-helmet-async";

import Accordionabout from "./componets/Accordionabout";
import Partener from "./componets/Partener";
var Scroll = require("react-scroll");

const Index = () => {
  useEffect(() => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <>
     <Helmet>
        <title>CJA | A Propos</title>
        <meta
          name="description"
          content="À Propos de CJA : Votre partenaire de confiance pour les comptoirs en aluminium à Djerba. Chez CJA, nous nous engageons à fournir des solutions élégantes et fonctionnelles pour vos besoins en aménagement. En tant que fier collaborateur de TPR, leader dans l'industrie, nous sommes déterminés à offrir des produits de qualité supérieure et un service exceptionnel pour sublimer vos espaces."
        />
        <link rel="canonical" href="/apropos" />
      </Helmet>
      
      <Header />
      <div className="position-breadcrumb">
  <nav aria-label="breadcrumb" className="d-inline-block">
    <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
      <li className="breadcrumb-item">
        <a href="/">Accueil</a>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        A Propos
      </li>
    </ul>
  </nav>
</div>
      <WhoWeAre />
    
      <Accordionabout/>
      <Partener/>
    </>
  );
};

export default Index;
