import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
var Scroll = require("react-scroll");
const Cathalogue = () => {
  const CathList = [
    {
      id: 1,
      name: "Volet Roulant",
      slug: "Volet roulant",
    },
    {
      id: 2,
      name: "Mur Rideau",
      slug: "mur rideau",
    },
    {
      id: 3,
      name: "Garde Coprs GS 85",
      slug: "Garde coprs GS 85 ",
    },
    {
      id: 4,
      name: "Gamme Prestige",
      slug: "Gamme Prestige",
    },
    {
      id: 5,
      name: "Confort",
      slug: "Confort ",
    },
    {
      id: 6,
      name: "75 Blindée",
      slug: "75 Blindée",
    },
    {
      id: 7,
      name: "67 et 40 Ligne Droite",
      slug: "67 et 40 Ligne droite ",
    },
    {
      id: 8,
      name: "16 CLOISON",
      slug: "16 CLOISON ",
    },
    {
      id: 9,
      name: "TW60",
      slug: "Catalogue TW60 version decembre 2022",
    },
    {
      id: 10,
      name: "TS60 Confort",
      slug: "Catalogue TS60 Confort version 2022 ",
    },
    {
      id: 11,
      name: "EX60 Prestige",
      slug: "Catalogue EX60 Prestige version 2022",
    },
    {
      id: 12,
      name: "EX45 Prestige",
      slug: "Catalogue EX45 PRESTIGE version 2022 ",
    },
    {
      id: 13,
      name: "67 Ellipse",
      slug: "Catalogue 67 ELLIPSE version 2022",
    },
    {
      id: 14,
      name: "40 Ellipse",
      slug: "Catalogue 40 ellipse version OCT 2022 ",
    },
  ];
  useEffect(() => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <>
      <div className="justify-content-center mt-100 mb-50">
        <div className="col-12 text-center">
          <div className="section-title ">
            <h4 className="title ">Nos Catalogues</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
            Explorez notre gamme exceptionnelle de produits en téléchargeant nos catalogues dès aujourd'hui. Découvrez en détail les solutions innovantes que nous offrons, et trouvez la réponse à vos besoins professionnels.
            </p>
          </div>
        </div>
      </div>

      <Container>
        <Row className="justify-content-md-center">
          {CathList.map((element) => {
            return (
              <Col sm={6} md={4}>
                <div className="card shop-list border-0 shadow position-relative overflow-hidden cursor-pointer mt-10">
                  <div className="shop-image position-relative overflow-hidden shadow d-flex justify-content-center">
                    <img
                      src={require("../../../../img/" + element.slug + ".png")}
                      className="img-fluid h-350px"
                      alt=""
                    />
                  </div>
                  <div className="card-body content p-4">
                    <a
                      href={require("../../../../img/" + element.slug + ".pdf")}
                      target="_blank"
                      className="text-dark product-name h6 d-flex justify-content-center"
                    >
                      {element.name}
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Cathalogue;
