import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "./catstyle.css";
import { EffectCube, Pagination } from "swiper/modules";

const NosCatalogues = () => {
  const navigate = useNavigate();
  const CathList = [
    {
      id: 1,
      name: "Volet Roulant",
      slug: "Volet roulant",
    },
    {
      id: 2,
      name: "Mur Rideau",
      slug: "mur rideau",
    },
    {
      id: 3,
      name: "Garde Coprs GS 85",
      slug: "Garde coprs GS 85 ",
    },
    {
      id: 4,
      name: "Gamme Prestige",
      slug: "Gamme Prestige",
    },
    {
      id: 5,
      name: "Confort",
      slug: "Confort ",
    },
    {
      id: 6,
      name: "75 Blindée",
      slug: "75 Blindée",
    },
    {
      id: 7,
      name: "67 et 40 Ligne Droite",
      slug: "67 et 40 Ligne droite ",
    },
    {
      id: 8,
      name: "16 CLOISON",
      slug: "16 CLOISON ",
    },
    {
      id: 9,
      name: "TW60",
      slug: "Catalogue TW60 version decembre 2022",
    },
    {
      id: 10,
      name: "TS60 Confort",
      slug: "Catalogue TS60 Confort version 2022 ",
    },
    {
      id: 11,
      name: "EX60 Prestige",
      slug: "Catalogue EX60 Prestige version 2022",
    },
    {
      id: 12,
      name: "EX45 Prestige",
      slug: "Catalogue EX45 PRESTIGE version 2022 ",
    },
    {
      id: 13,
      name: "67 Ellipse",
      slug: "Catalogue 67 ELLIPSE version 2022",
    },
    {
      id: 14,
      name: "40 Ellipse",
      slug: "Catalogue 40 ellipse version OCT 2022 ",
    },
  ];
  return (
    <>
    <section className="section">
    <div >
    <div className="justify-content-center">
        <div className="text-center">
          <div className="section-title ">
            <h4 className="title ">
              Explorez nos produits en téléchargeant notre catalogues
            </h4>
          </div>
        </div>
      </div>
      <Swiper
      onClick={()=>navigate("/catalogues")}
      style={{width:"280px",height:"350px",left:"50%", top:"50%",marginLeft:"-150px",marginTop:"-150px"}}
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        pagination={true}
        modules={[EffectCube, Pagination]}
        className="mySwiper mt-20 mb-20"
      >
        {CathList.map((element) => {
            return (
                <SwiperSlide>
                <img
                   src={require("../../../../img/" + element.slug + ".png")}
                />
              </SwiperSlide>
            );
          })}

      
      </Swiper>
      <div className="container ">
        <div className="justify-content-center">
          <div className="text-center">
            <div className="section-title ">
              <p className="text-muted para-desc mx-auto">
                Optimisez votre expertise en découvrant nos catalogues
                téléchargeables. Explorez notre assortiment de produits de
                pointe pour répondre à vos besoins professionnels avec précision
                et efficacité. Téléchargez maintenant pour prendre des décisions
                éclairées.
              </p>
              <div className="mt-4">
                <a
                  onClick={() => navigate("/catalogues")}
                  className="btn btn-primary mt-2 me-2"
                >
                  Accéder aux Catalogues
                </a>
                <a
                  onClick={() => navigate("/contact")}
                  className="btn btn-outline-primary mt-2"
                >
                  Contactez-Nous+
                </a>
              </div>
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </div>

    </section>
    
     
    </>
  );
};

export default NosCatalogues;
