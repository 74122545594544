import React from "react";
import { useNavigate } from "react-router-dom";
import "./card.css";
import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FourBox = () => {
 
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);
  const navigate = useNavigate();

  const CathList = [
    {
      id: 1,
      name: "Volet Roulant",
      slug: "Volet roulant",
    },
    {
      id: 2,
      name: "Mur Rideau",
      slug: "mur rideau",
    },
    {
      id: 3,
      name: "Garde Coprs GS 85",
      slug: "Garde coprs GS 85 ",
    },
    {
      id: 4,
      name: "Gamme Prestige",
      slug: "Gamme Prestige",
    },
    {
      id: 5,
      name: "Confort",
      slug: "Confort ",
    },
    {
      id: 6,
      name: "75 Blindée",
      slug: "75 Blindée",
    },
    {
      id: 7,
      name: "67 et 40 Ligne Droite",
      slug: "67 et 40 Ligne droite ",
    },
    {
      id: 8,
      name: "16 CLOISON",
      slug: "16 CLOISON ",
    },
    {
      id: 9,
      name: "TW60",
      slug: "Catalogue TW60 version decembre 2022",
    },
    {
      id: 10,
      name: "TS60 Confort",
      slug: "Catalogue TS60 Confort version 2022 ",
    },
    {
      id: 11,
      name: "EX60 Prestige",
      slug: "Catalogue EX60 Prestige version 2022",
    },
    {
      id: 12,
      name: "EX45 Prestige",
      slug: "Catalogue EX45 PRESTIGE version 2022 ",
    },
    {
      id: 13,
      name: "67 Ellipse",
      slug: "Catalogue 67 ELLIPSE version 2022",
    },
    {
      id: 14,
      name: "40 Ellipse",
      slug: "Catalogue 40 ellipse version OCT 2022 ",
    },
  ];
  return (
    <>
            {/* modal1 */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Certifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Swiper
    
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper swiper-slide1"
          >
            <SwiperSlide>
              <img src={require("../../../../img/QUALANOD.jpg")} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../../img/QULICOAT.jpg")} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../../img/AFAQ.jpg")} />
            </SwiperSlide>
          </Swiper>

        </Modal.Body>
      </Modal>
            {/* modal 3 */}
      <Modal
        size="lg"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Une variété d'accessoires
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Tous les accessoires sont disponibles dans notre comptoir, cependant, veuillez noter que les images de ces accessoires ne sont pas encore disponibles sur notre site web. Pour obtenir de plus amples détails, nous vous invitons à nous contacter.
        <div className="mt-4 d-flex jcc">
                <a
                  onClick={() => navigate("/produits")}
                  className="btn btn-primary mt-2 me-2"
                >
                  Accéder aux Produits
                </a>
                <a
                  onClick={() => navigate("/contact")}
                  className="btn btn-outline-primary mt-2"
                >
                  Contactez-Nous
                </a>
              </div>

        </Modal.Body>
      </Modal>

            {/* modal 2 */}
      <Modal
        size="lg"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Catalogues  
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <Container>
        <Row className="justify-content-md-center">
          {CathList.map((element) => {
            return (
              <Col sm={6} md={4}>
                <div className="card shop-list border-0 shadow position-relative overflow-hidden cursor-pointer mt-10">
                  <div className="shop-image position-relative overflow-hidden shadow d-flex justify-content-center">
                    <img
                      src={require("../../../../img/" + element.slug + ".png")}
                      className="img-fluid h-350px"
                      alt=""
                    />
                  </div>
                  <div className="card-body content p-4">
                    <a
                      href={require("../../../../img/" + element.slug + ".pdf")}
                      target="_blank"
                      className="text-dark product-name h6 d-flex justify-content-center"
                    >
                      {element.name}
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

        </Modal.Body>
      </Modal>

  {/* modal 4 */}
      <Modal
        size="lg"
        show={lgShow4}
        onHide={() => setLgShow4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Livraison  
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        Nous sommes fiers d'offrir un service de livraison qui vous permet de recevoir nos produits où que vous soyez. Peu importe votre emplacement, notre équipe dévouée est prête à vous fournir un service rapide et fiable. Pour passer votre commande, veuillez nous contacter dès aujourd'hui. Votre satisfaction est notre priorité, et nous sommes impatients de vous servir.

        <div className="mt-4 d-flex jcc">
                <a
                  onClick={() => navigate("/contact")}
                  className="btn btn-outline-primary mt-2"
                >
                  Contactez-Nous
                </a>
              </div>

        </Modal.Body>
      </Modal>

      <section className="section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="features-absolute">
                <div className="row">
                  <div
                    className="col-lg-3 col-md-6 col-12 "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="card1"
                      onClick={() => setLgShow3(true)}
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          height="50"
                          width="50"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          version="1.1"
                          id="heart"
                          class="bi bi-patch-check-fill"
                          xmlSpace="preserve"
                        >
                          <linearGradient id="gradientColor">
                            <stop offset="5%" stopColor="#1174f5" />
                            <stop offset="95%" stopColor="#f06262" />
                          </linearGradient>
                          <g>
                            <path
                              fill-rule="evenodd"
                              d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                            />
                          </g>
                        </svg>
                      </div>
                      <p className="title">Une variété d'accessoires</p>
                      <p className="text">
                        Trouvez tous les produits chez nous
                      </p>
                    </div>
                  </div>
                  {/*end col*/}
                  <div
                    className="col-lg-3 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="card1" onClick={() => setLgShow(true)}>
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          height="50"
                          width="50"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          version="1.1"
                          id="heart"
                          class="bi bi-patch-check-fill"
                          xmlSpace="preserve"
                        >
                          <linearGradient id="gradientColor">
                            <stop offset="5%" stopColor="#1174f5" />
                            <stop offset="95%" stopColor="#f06262" />
                          </linearGradient>
                          <g>
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                          </g>
                        </svg>
                      </div>
                      <p className="title">Certifications</p>
                      <p className="text">
                        Certification QUALANOD <br />
                        Certification L’AFAQ : L’ISO 9001
                      </p>
                    </div>
                  </div>
                  {/*end col*/}
                  <div
                    className="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="card1"   onClick={() => setLgShow4(true)}>
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          height="50"
                          width="50"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          version="1.1"
                          id="heart"
                          class="bi bi-patch-check-fill"
                          xmlSpace="preserve"
                        >
                          <linearGradient id="gradientColor">
                            <stop offset="5%" stopColor="#1174f5" />
                            <stop offset="95%" stopColor="#f06262" />
                          </linearGradient>
                          <g>
                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                          </g>
                        </svg>
                      </div>
                      <p className="title">livraison</p>
                      <p className="text">
                        Nous livrons nos produits chez vous, peu importe où vous
                        êtes
                      </p>
                    </div>
                  </div>
                  {/*end col*/}
                  <div
                    className="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="card1"
                      onClick={() => setLgShow2(true)}
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          height="50"
                          width="50"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          version="1.1"
                          id="heart"
                          class="bi bi-patch-check-fill"
                          xmlSpace="preserve"
                        >
                          <linearGradient id="gradientColor">
                            <stop offset="5%" stopColor="#1174f5" />
                            <stop offset="95%" stopColor="#f06262" />
                          </linearGradient>
                          <g>
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                          </g>
                        </svg>
                      </div>
                      <p className="title">Catalogues</p>
                      <p className="text">
                        Explorez notre catalogue pour découvrir nos produits
                      </p>
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        {/*end container*/}
      </section>
    </>
  );
};

export default FourBox;

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  fill="currentColor"
  class="bi bi-book"
  viewBox="0 0 16 16"
>
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
</svg>;
