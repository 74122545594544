import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./swiper.css";

// import required modules
import { EffectCoverflow } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper/modules";
const NosProduits = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="section ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title ">
                <h4 className="title ">
                  Découvrez notre vaste sélection de profilés en aluminium
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <Swiper
              style={{
                width: "100%",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[EffectCoverflow, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  src={require("../../../../img/serie-grille-de-ventilation.png")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../../img/serie-volets-coulissants.png")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie15_ellipse.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../../img/serie52_horizon_rpt_vep.png")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie52_horizon_vec.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_160_confort.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_22_ellipse.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_40_elipse.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_75_blindee.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_ex45_prestige.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../../img/serie_ex60_prestige_ligne_elliptique.png")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../../img/serie_tw60_confort.png")} />
              </SwiperSlide>
            </Swiper>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title ">
                <p className="text-muted para-desc mx-auto mb-0">
                  Découvrez notre vaste sélection de profilés en aluminium de
                  haute qualité. Chez CJA, nous proposons une variété de formes,
                  de tailles et de finitions pour répondre à vos besoins
                  spécifiques.
                </p>
                <div className="mt-4">
                  <a
                    onClick={() => navigate("/produits")}
                    className="btn btn-primary mt-2 me-2"
                  >
                    Accéder aux Produits
                  </a>
                  <a
                    onClick={() => navigate("/contact")}
                    className="btn btn-outline-primary mt-2"
                  >
                    Contactez-Nous
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
    </>
  );
};

export default NosProduits;
