import React, { useEffect } from "react";
import Carousel from "./components/Carousel";
import SponsorSwiper from "./components/SponsorSwiper";

import NosProduits from "./components/NosProduits";
import WhoWeAre from "./components/WhoWeAre";
import FourBox from "./components/FourBox";
import ColorProduct from "../../generalComponents/ColorProduct";
import NosCatalogues from "./components/NosCatalogues";
import { Helmet } from "react-helmet-async";

var Scroll = require("react-scroll");

const Home = () => {
  useEffect(() => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>CJA Comptoir Jerba d'Aluminium</title>
        <meta
          name="description"
          content="Découvrez la gamme exclusive de comptoirs en aluminium CJA à Djerba, la destination par excellence pour l'élégance et la fonctionnalité. CJA est le plus grand comptoir de qualité au sud de la Tunisie, conçu pour apporter une touche moderne et raffinée à votre espace. Optez pour l'excellence avec nos comptoirs en aluminium de première qualité, parfaitement adaptés à vos besoins."
        />
        <link rel="canonical" href="/" />
      </Helmet>

      <Carousel />

      <FourBox />
      <WhoWeAre />
      <ColorProduct button={true} />

      <NosProduits />

      <NosCatalogues />

      <SponsorSwiper />
    </>
  );
};

export default Home;
