import React, { useEffect } from "react";
import Map from "./componets/Map";
import Header from "./componets/Header";

import { Helmet } from "react-helmet-async";
var Scroll = require("react-scroll");

const Index = () => {
  useEffect(() => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop({ duration: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>CJA | Contactez-Nous</title>
        <meta
          name="description"
          content="Contactez-nous dès aujourd'hui chez CJA, votre référence en comptoirs en aluminium à Djerba. Pour toute demande d'information ou de devis, n'hésitez pas à nous joindre au +216 75668326 ou au +216 29940541, ou à nous écrire à l'adresse cja.jerba@gmail.com. Notre équipe dévouée est prête à répondre à vos besoins en matière de comptoirs en aluminium de qualité supérieure."
        />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <Header />
      <div className="position-breadcrumb">
        <nav aria-label="breadcrumb" className="d-inline-block">
          <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
            <li className="breadcrumb-item">
              <a href="/">Accueil</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact
            </li>
          </ul>
        </nav>
      </div>
      <Map />
    </>
  );
};

export default Index;
