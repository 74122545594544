import React from "react";
import { useNavigate } from "react-router-dom";

const WhoWeAre = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row align-items-center" id="counter">
            <div className="col-md-6">
              <img
                src={require("../../../../img/whoweare.png")}
                class="img-fluid"
                alt=""
              />
            </div>

            {/*end col*/}
            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ms-lg-4">
                {/* <div className="d-flex mb-4">
                  <span className="text-primary h1 mb-0">
                    <span
                      className="counter-value display-1 fw-bold"
                      data-target={15}
                    >
                      +4
                    </span>
                  </span>
                  <span className="h6 align-self-end ms-2">
                    Ans <br /> d'Expérience
                  </span>
                </div> */}
                <div className="section-title">
                  <h4 className="title mb-4">Qui sommes-nous ?</h4>
                  <p className="text-muted">
                    CJA est une entreprise commerciale qui se
                    spécialise dans la distribution de produits en aluminium en
                    gros et au détail, notamment des profils en aluminium, des
                    volets roulants, des accessoires, des moteurs, etc., ainsi
                    que des machines destinées à l'industrie de la menuiserie
                    aluminium. De plus, CJA est fière de représenter TPR dans
                    les régions de Djerba et Gabès. En outre, l'entreprise
                    propose une gamme de services à ses clients, notamment la
                    livraison et un service après-vente complet, englobant la
                    maintenance des machines et la réparation des moteurs
                    tubulaires.
                  </p>
                  <a
                    onClick={() => navigate("/contact")}
                    className="btn btn-primary mt-3"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/logocjabw.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/tpr_who.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            {/*end col*/}
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/alustar_who.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            {/*end col*/}
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/logocjabw.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/tpr_who.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            {/*end col*/}
            <div className="col-lg-2 col-md-2 col-6 text-center pt-4">
              <img
                src={require("../../../../img/alustar_who.png")}
                className="avatar avatar-ex-sm"
                alt=""
              />
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
    </>
  );
};

export default WhoWeAre;
