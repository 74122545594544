import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import ProductLists from "./ProductList";
import { ProductsTypeListe } from "../../../../enum/ProductList";

const Containers = () => {
  const [productTypeId, setProductTypeId] = useState(1);

  const handleClick = (id) => {
    setProductTypeId(id);
  };
  return (
    <div className="container mb-20">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-12">
          <div className="card border-0 sidebar sticky-bar">
            <div className="card-body p-0">
              {/* Categories */}
              <div className="widget mt-4">
                <ul
                  className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar"
                  id="pills-tab"
                  role="tablist"
                >
                  {ProductsTypeListe.map((elt) => {
                    return (
                      <li
                        className="nav-item"
                        onClick={() => handleClick(elt.id)}
                      >
                        <a
                          className={
                            productTypeId === elt.id
                              ? "nav-link rounded active"
                              : "nav-link rounded "
                          }
                          id="webdeveloping"
                          data-bs-toggle="pill"
                          href="#developing"
                          role="tab"
                          aria-controls="developing"
                          aria-selected="false"
                        >
                          <div className="text-center py-1 d-flex">
                            <img src={elt.logo} height={elt.h} width={elt.w} />
                           
                          </div>
                        </a>
                        {/*end nav link*/}
                      </li>
                    );
                  })}
                </ul>
                {/*end nav pills*/}

                {/*end row*/}
              </div>
              {/* Categories */}
            </div>
          </div>
        </div>
        {/*end col*/}
        <div className="col-lg-9 col-md-8 col-12 pt-2 mt-sm-0 pt-sm-0">
          {/*end row*/}
          <ProductLists productTypeId={productTypeId} />
          {/*end row*/}
        </div>
        {/*end col*/}
      </div>
      {/*end row*/}
    </div>
  );
};

export default Containers;
