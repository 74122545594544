import React from "react";
import Navbar from "./componets/Navbar";
import Content from "./componets/Content";
import Footer from "./componets/Footer";

const index = () => {
  return (
    <>
      <Navbar />
      <div className="mt-76">
        <Content />
      </div>
      <div style={{marginTop:"40px"}}>
      <Footer />
      </div>
     
      <a
        href="#"
        onclick="topFunction()"
        id="back-to-top"
        className="back-to-top fs-5"
        style={{ display: "block" }}
      >
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-up fea icon-sm icons align-middle"
        >
          <line x1={12} y1={19} x2={12} y2={5} />
          <polyline points="5 12 12 5 19 12" />
        </svg>
      </a>
    </>
  );
};

export default index;
