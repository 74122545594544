import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';

function Partener() {
    return (
    <>
    <div className="justify-content-center mt-50 mb-50">
            <div className="col-12 text-center">
              <div className="section-title ">
                <h4 className="title ">Nos Partenaires</h4>
              </div>
            </div>
     
          </div>

          <Container>
        <Row>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/tpr.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/alustar.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/akafix.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/asa.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/came.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/lavaal.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/master.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/panda.jpeg')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/somfy.png')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/spi.jpg')} thumbnail style={{height:"150px"}} />
          </Col>
          <Col xs={6} md={4}>
            <Image src={require('../../../../img/yilmaz.png')} thumbnail style={{height:"150px"}} />
          </Col>
        
        </Row>
      </Container>
    </>
      
    );
  }
  
  export default Partener;