import { scale } from "@cloudinary/url-gen/actions/resize";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="footer-py-60 text-center">
              <div className="row py-5">
                <div className="col-md-4">
                  <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                    <div className="icons text-center mx-auto">
                    <i class="bi bi-telephone" style={{scale:"200%"}}/>
                    </div>
                    <div className="content mt-4">
                      <h5 className="footer-head">Telephone</h5>
                      <p style={{color : "#cfd0d4"}}>
                      Votre référence pour l'aluminium de qualité
                      </p>
                      <a href="tel:+216-29940541" className="text-foot">
                      +216 75668326, +216 29940541
                      </a>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                    <div className="icons text-center mx-auto">
                    <i class="bi bi-envelope" style={{scale:"200%"}}/>
                    </div>
                    <div className="content mt-4">
                      <h5 className="footer-head">Email</h5>
                      <p style={{color : "#cfd0d4"}}>
                      Pour toute question ou demande, contactez-nous, notre équipe sera ravie de vous aider
                      </p>
                      <a
                        href="mailto:cja.jerba@gmail.com"
                        className="text-foot"
                      >
                        cja.jerba@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                    <div className="icons text-center mx-auto">
                    <i class="bi bi-geo-alt-fill " style={{scale:"200%"}}/>
                    </div>
                    <div className="content mt-4">
                      <h5 className="footer-head">Localisation</h5>
                      <p style={{color : "#cfd0d4"}}>
                        CJA مصرف جربه للالمنيوم, <br />
                        Houmt Souk
                      </p>
                      <a
                        href="https://www.google.tn/maps/place/CJA+%D9%85%D8%B5%D8%B1%D9%81+%D8%AC%D8%B1%D8%A8%D9%87+%D9%84%D9%84%D8%A7%D9%84%D9%85%D9%86%D9%8A%D9%88%D9%85%E2%80%AD/@33.8540539,10.8643664,17z/data=!3m1!4b1!4m6!3m5!1s0x13aaa5fe61902185:0xd02370249d477b6!8m2!3d33.8540539!4d10.8669413!16s%2Fg%2F11v9kmydnk?hl=fr&entry=ttu"
                        data-type="iframe"
                        className="video-play-icon text-foot lightbox"
                      >
                        View on Google map
                      </a>
                    </div>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
      {/*end container*/}

      <div className="footer-py-30 footer-bar bg-footer">
  <div className="container text-center">
    <div className="row align-items-center">
      <div className="col-sm-3">
        <div className="text-sm-start">
        <a href="#" className="logo-footer">
                  <img
                    src={require("../../../img/logocjabw.png")}
                    height={80}
                  />
                </a>
        </div>
      </div>
      <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div className="text-center">
          <p className="mb-0">
            © 2023 CJA. Design by{" "}
            <a
              href="https://www.linkedin.com/in/khaireddine-baatour/"
              target="_blank"
              className="text-reset"
            >
              Khaireddine
            </a>
            . All rights reserved
          </p>
        </div>
      </div>
      <div className="col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0" >
        <ul className="list-unstyled social-icon foot-social-icon text-sm-end mb-0">
          <li className="list-inline-item mb-0">
            <a href="https://www.facebook.com/p/Comptoir-Jerba-DAluminium-100063611529280/" target="_blank" className="rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-facebook fea icon-sm fea-social"
              >
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
              </svg>
            </a>
          </li>
          <li className="list-inline-item mb-0">
            <a href="https://www.instagram.com/cjadjerba/" target="_blank" className="rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-instagram fea icon-sm fea-social"
              >
                <rect x={2} y={2} width={20} height={20} rx={5} ry={5} />
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </a>
          </li>
          <li className="list-inline-item mb-0">
            <a  className="rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-linkedin fea icon-sm fea-social"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                <rect x={2} y={9} width={4} height={12} />
                <circle cx={4} cy={4} r={2} />
              </svg>
            </a>
          </li>
        
          
        </ul>
        {/*end icon*/}
      </div>
      {/*end col*/}
    </div>
    {/*end row*/}
  </div>
  {/*end container*/}
</div>
-
    </footer>
  );
};

export default Footer;
