import React, { useEffect, useState } from "react";

const images = [
  require("../../../../img/a.jpg"),
  require("../../../../img/b.jpg"),
  require("../../../../img/c.jpg"),
  require("../../../../img/d.jpg"),
  require("../../../../img/e.jpg"),
  require("../../../../img/f.jpg"),
];
const titlesAndParagraphs = [
  {
    title: "Profils en aluminium TPR de haute qualité",
    paragraph:
      "Bénéficiez de profils en aluminium TPR haut de gamme, durables et au design moderne, répondant aux normes industrielles les plus élevées.",
  },
  {
    title: "Solutions en aluminium personnalisables",
    paragraph:
      "Chez CJA, chaque projet est unique. Nos solutions en aluminium sont personnalisables pour répondre à vos besoins spécifiques en termes de longueur, de finition et de forme.",
  },
  {
    title: "Durabilité dans la production d'aluminium",
    paragraph:
      "Engagement envers la durabilité. Nos pratiques éco-responsables réduisent les déchets et préservent l'environnement. Optez pour CJA, votre choix écologique en aluminium.",
  },
  {
    title: "Produits en aluminium de haute performance",
    paragraph: "Découvrez nos produits en aluminium de haute performance qui sont conçus pour répondre aux exigences les plus rigoureuses de l'industrie. Nos solutions offrent une durabilité exceptionnelle et une performance supérieure."
  },
  {
    title: "Experts en aluminium",
    paragraph:
      "Des décennies d'expérience dans l'industrie de l'aluminium. CJA, synonyme de confiance. Nos experts sont dévoués à fournir des produits de qualité et un service exceptionnel. Joignez-vous à nos clients satisfaits.",
  },
  {
    title: "Une gamme diversifiée de profilés en aluminium",
    paragraph:
      "Découvrez notre large gamme de profilés en aluminium de qualité supérieure. Chez CJA, nous offrons diverses formes, tailles et finitions pour répondre à vos besoins spécifiques. Nos profilés en aluminium garantissent durabilité, polyvalence et performance. "
}

];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index to switch to the next image
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4500); // Adjust the delay as needed

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
    <section
      className="bg-half-400 d-table w-100 position-relative"
      style={{
        backgroundImage: `url(${images[currentIndex]})`,
      }}
    >
      <div className="bg-overlay-2" />
      <div className="container bg-overlaykb1">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="pages-heading title-heading">
              <h2 className="text-white" >
                {titlesAndParagraphs[currentIndex].title}
              </h2>

              <p className="para-desc mb-0 mx-auto" style={{color : "#cecece"}} >
                {titlesAndParagraphs[currentIndex].paragraph}
              </p>
            </div>
          </div>

        </div>
        
      </div>{" "}
  
    </section>
    <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Carousel;
