import React, { useState } from "react";

const Accordionabout = () => {
  const [activeClassId, setActiveClassId] = useState("headingOne");
  const [shown, setShown] = useState(true);
  const handleClick = (id) => {
    setActiveClassId(id);
    setShown(!shown);
  };
  const accordionList = [
    {
      id: "headingOne",
      title: "Excellence Aluminium : Notre Partenariat avec TPR",
      discreption:
        "Notre entreprise, CJA, est fière de sa collaboration étroite avec la société TPR, leader de l'industrie de l'aluminium en Tunisie. Cette alliance stratégique nous permet de vous offrir des produits d'aluminium de la plus haute qualité, soutenus par l'expertise inégalée et la réputation exemplaire de TPR. Grâce à cette relation solide, nous avons un accès privilégié aux dernières innovations technologiques et aux normes de qualité les plus rigoureuses de l'industrie. Vous pouvez ainsi avoir confiance en la fiabilité et la durabilité de chaque produit que nous proposons. Notre engagement envers l'excellence s'appuie sur cette collaboration fructueuse, garantissant que nous sommes en mesure de répondre à vos besoins avec des solutions en aluminium de premier ordre.",
      target: "#collapseOne",
      control: "collapseOne",
      labelledby: "headingOne",
    },
    {
      id: "headingTwo",
      title: "Qualité Certifiée, Excellence Garantie",
      discreption:
        "Nos produits d'aluminium sont certifiés selon les normes ISO les plus strictes, gage de qualité inégalée. En collaborant avec TPR, nous vous offrons la garantie de produits qui ont remporté des labels de qualité de renom. Chez CJA, la qualité est notre priorité, et nos certifications en témoignent.",
      target: "#collapseTwo",
      control: "collapseTwo",
      labelledby: "headingTwo",
    },
    {
      id: "headingThree",
      title: "Qualité et Service Exceptionnels",
      discreption:
        " Chez CJA, votre satisfaction est notre obsession. Nous vous proposons non seulement des produits de qualité supérieure, mais également un service client hors pair. Notre équipe dédiée est là pour vous guider, de la sélection de produits à l'après-vente. En plus d'une livraison rapide et fiable, nous vous offrons des services sur mesure et des conseils d'experts pour vous aider à faire les choix les plus judicieux. Besoin d'un support technique ? Nous sommes là pour répondre à toutes vos questions. Faites confiance à CJA pour une expérience client exceptionnelle.",
      target: "#collapseThree",
      control: "collapseThree",
      labelledby: "headingThree",
    },
  ];

  return (
    <>
      <div className="justify-content-center mt-50">
        <div className="col-12 text-center">
          <div className="section-title ">
            <h4 className="title ">Nos Valeurs Fondamentales</h4>
          </div>
        </div>
      </div>
      <div className="container mt-20">
        <div className="row align-items-center justify-content-center d-flex">
          <div className="col-lg-7 col-md-6">
            <div className="accordion " id="accordionExample">
 

              {accordionList.map((item) => (
                <div
                  className="accordion-item rounded shadow mt-2"
                  onClick={() => handleClick(item.id)}
                >
                  <h2 className="accordion-header" id={activeClassId}>
                    <button
                      className={
                        activeClassId === item.id && shown
                          ? "accordion-button border-0 bg-light "
                          : "accordion-button border-0 bg-light collapsed"
                      }
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={item.target}
                      aria-expanded={
                        activeClassId === item.id ? "true" : "false"
                      }
                      aria-controls={item.control}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={
                      activeClassId === item.id && shown
                        ? "accordion-collapse border-0 collapse show"
                        : "accordion-collapse border-0 collapse"
                    }
                    aria-labelledby={item.labelledby}
                    data-bs-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="accordion-body text-muted">
                      {item.discreption}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </>
  );
};

export default Accordionabout;
