import { Formik } from "formik";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import * as Yup from "yup";
import Dropdown from "react-bootstrap/Dropdown";
import "./button.css";
const Map = () => {
  const [selectedLocation, setSelectedLocation] = useState("Djerba"); // Default location

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6  mt-4 pt-2">
              <div className="card shadow rounded border-0">
                <div className="card-body py-5">
                  <h4 className="card-title">Contactez Nous</h4>
                  <div className="custom-form mt-3">
                    <Formik
                      initialValues={{
                        user_email: "",
                        user_phone: "",
                        user_name: "",
                        message: "",
                      }}
                      validationSchema={Yup.object().shape({
                        user_email: Yup.string().required("Nécessaire"),
                        user_phone: Yup.string().required("Nécessaire"),
                        user_name: Yup.string().required("Nécessaire"),
                        message: Yup.string().required("Nécessaire"),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        emailjs
                          .send(
                            "service_7rumg0o",
                            "template_2zjoj7q",
                            values,
                            "Cxnp0R7dMvqe6obgW"
                          )
                          .then(
                            function (response) {
                              resetForm();
                              alert("Envoyer avec succer");
                            },
                            function (error) {
                              alert("faild");
                            }
                          );
                      }}
                    >
                      {({
                        values,

                        handleChange,
                        errors,
                        handleSubmit,

                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <p id="error-msg" className="mb-0" />
                          <div id="simple-msg" />
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Nom et Prénom{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-user fea icon-sm icons"
                                  >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                    <circle cx={12} cy={7} r={4} />
                                  </svg>
                                  <input
                                    type="text"
                                    name="user_name"
                                    onChange={handleChange}
                                    value={values.user_name}
                                    className="form-control ps-5"
                                    placeholder="Nom et prénom :"
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.user_name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Adresse e-mail{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-mail fea icon-sm icons"
                                  >
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                    <polyline points="22,6 12,13 2,6" />
                                  </svg>
                                  <input
                                    type="email"
                                    name="user_email"
                                    onChange={handleChange}
                                    value={values.user_email}
                                    className="form-control ps-5"
                                    placeholder="Email :"
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.user_email}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end col*/}
                            <div className="col-12">
                              <div className="mb-3">
                                <label className="form-label">Téléphone</label>
                                <span className="text-danger">*</span>
                                <div className="form-icon position-relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-book fea icon-sm icons"
                                  >
                                    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
                                    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
                                  </svg>
                                  <input
                                    type="number"
                                    name="user_phone"
                                    onChange={handleChange}
                                    value={values.user_phone}
                                    className="form-control ps-5"
                                    placeholder="Téléphone :"
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.user_phone}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end col*/}
                            <div className="col-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Message <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-message-circle fea icon-sm icons clearfix"
                                  >
                                    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
                                  </svg>
                                  <textarea
                                    name="message"
                                    onChange={handleChange}
                                    value={values.message}
                                    rows={4}
                                    className="form-control ps-5"
                                    placeholder="Message :"
                                    defaultValue={""}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-grid">
                                {/* <button
                                  type="submit"
                                  id="submit"
                                  name="send"
                                  className="btn btn-primary"
                                >
                                  Send Message
                                </button> */}
                                <button type="submit" id="submit" name="send">
                                  <div class="svg-wrapper-1">
                                    <div class="svg-wrapper">
                                      <svg
                                        height="24"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path
                                          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                  <span>Envoyer Message</span>
                                </button>
                              </div>
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </form>
                      )}
                    </Formik>
                  </div>
                  {/*end custom-form*/}
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-8 col-md-6 ps-md-3 pe-md-3 mt-4 pt-2">
              <Dropdown style={{ position: "relative", marginBottom: "15px" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Map: {selectedLocation}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleLocationChange("Djerba")}>
                    Djerba
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLocationChange("Gabes")}>
                    Gabes
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="card map map-height-two rounded map-gray border-0">
                <div className="card-body p-0 d-flex justify-content-center">
                  {selectedLocation === "Djerba" && (
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.3470852848617!2d10.866284008361335!3d33.853881400516116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13aaa5fe61902185%3A0xd02370249d477b6!2zQ0pBINmF2LXYsdmBINis2LHYqNmHINmE2YTYp9mE2YXZhtmK2YjZhQ!5e0!3m2!1sfr!2stn!4v1692970725753!5m2!1sfr!2stn"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      width={600}
                      height={450}
                    />
                  )}
                  {selectedLocation === "Gabes" && (
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d390.90453088007314!2d10.078657279309288!3d33.892521872025064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12557020f665d2e9%3A0x65fba3d4563fd9fa!2sChenini%20Nahal!5e1!3m2!1sfr!2stn!4v1694814204734!5m2!1sfr!2stn"
                      width={600}
                      height={450}
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  )}
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        {/*end container*/}
      </section>
    </>
  );
};

export default Map;

<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d390.90453088007314!2d10.078657279309288!3d33.892521872025064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12557020f665d2e9%3A0x65fba3d4563fd9fa!2sChenini%20Nahal!5e1!3m2!1sfr!2stn!4v1694814204734!5m2!1sfr!2stn"
  width={600}
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>;
